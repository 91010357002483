.fc .fc-daygrid-event,
.fc .fc-daygrid-day-frame {
  border: none;
}
th.fc-col-header-cell.fc-day,
.fc-theme-standard td,
table.fc-scrollgrid.fc-scrollgrid-liquid {
  border: none;
}

.fc .fc-daygrid-day-frame {
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
}
@media screen and (max-width: 600px) {
  .fc .fc-daygrid-day-frame {
    padding: 5px; /* Adjust the padding for smaller screens */
  }
}
.fc .fc-button-primary {
  background-color: white;
  border-color: #E3ECFD;
  color: #00104d;
  border-radius: 50%;
  padding: 5px;
}


.fc .fc-button-primary:hover {
  background-color: #E3ECFD;
  border-color: #B5CFFC;
  color: #00104d;
}

.fc .fc-button-primary:disabled:hover, .fc .fc-button-primary:disabled{
  background-color: #F1F4F9;
  border-color: #F1F4F9;
  color: #626C93;
}
.fc .fc-toolbar-title,
.fc .fc-col-header-cell-cushion {
  color: #00104d;
}


.fc .fc-toolbar-title{
  font-size: 16px;
}

.fc .fc-col-header-cell-cushion {
  font-size: 14px;
}

.fc-daygrid-day-top,
.fc .fc-daygrid-day-number {
  width: 100%;
}

.fc .fc-day-disabled{
  background-color: white;
}

.fc .fc-daygrid-day.fc-day-today{
  background-color: white;
}